import React from 'react'
import kiranaImage from '../../Assest/feature/kirana-grocery-and-fmcg.svg';
import bakeryImage from '../../Assest/feature/bakery-confectionery.svg';
import electronicsImage from '../../Assest/feature/electronics.svg';
import stationeryImage from '../../Assest/feature/stationery.svg';
import watchesImage from '../../Assest/feature/watches.svg';
import carAccessoriesImage from '../../Assest/feature/car-accessories.svg';
import booksImage from '../../Assest/feature/books.svg';
import luggageImage from '../../Assest/feature/luggage.svg';
import restaurantImage from '../../Assest/feature/restaurant-cafe.svg';
import fashionImage from '../../Assest/feature/fashion-furninshing.svg';
import fruitsImage from '../../Assest/feature/fruits-vegetables.svg';
import opticsImage from '../../Assest/feature/opticals.svg';
import jwellery from '../../Assest/feature/jwellery.svg';
import mobile from '../../Assest/feature/mobile-tablets-and-wearables.svg';
import home from '../../Assest/feature/home-kitchen-appliances.svg';
import sweets from '../../Assest/feature/sweets-savouries.svg';
import dryfruits from '../../Assest/feature/dry-fruits-dates-seeds.svg';
import meat from '../../Assest/feature/meat-poultry.svg';
import furniture from '../../Assest/feature/furniture-home-decor.svg';
import footwear from '../../Assest/feature/footwear.svg';
import sports from '../../Assest/feature/sports-accessories.svg';
import paints from '../../Assest/feature/paints-hardware.svg';
import pet from '../../Assest/feature/pet-food-accessories.svg';
import gifts from '../../Assest/feature/gifts-toys.svg';

const ProductCategory = () => {
  return (
   <>
     <div className="container bg-sky-50 mx-auto py-8 px-20"> 
      <div className="grid grid-cols-2  md:grid-cols-4 lg:grid-cols-6 gap-6">
        {categories.map((category, index) => (
            <div  key={index} >
          <div className="bg-gray-100 h-48 flex justify-center w-full p-5 rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition duration-300 ease-in-out">
            <img 
              src={category.image}
              alt={category.text}
              className="" 
            />
          </div>
           <div className="p-4">
           <h3 className="text-base text-center font-medium text-gray-800">{category.text}</h3>
         </div>
         </div>
        ))}
      </div>
    </div>
   </>
  )
}

export default ProductCategory


const categories = [
    {
      image: 'https://app.apnabillbook.com/uploads/1727977062743kirana-grocery-and-fmcg.svg',
      text: 'Kirana, Grocery and FMCG',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727976546885bakery-confectionery.svg',
      text: 'Bakery & Confectionery',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727977062726electronics.svg',
      text: 'Electronics',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727977062754stationery.svg',
      text: 'Stationery',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727977062759watches.svg',
      text: 'Watches',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727976546890car-accessories.svg',
      text: 'Car Accessories',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727976546886books.svg',
      text: 'Books',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727977062745luggage.svg',
      text: 'Luggage',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727977062752restaurant-cafe.svg',
      text: 'Restaurant & Cafe',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727977062727fashion-furninshing.svg',
      text: 'Fashion & Furninshing',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727977062734fruits-vegetables.svg',
      text: 'Fruits & Vegetables',
    },
    {
      image: 'https://app.apnabillbook.com/uploads/1727977062748opticals.svg',
      text: 'Opticals',
    },
    {
        image: 'https://app.apnabillbook.com/uploads/1727977062742jwellery.svg', 
        text: 'Jwellery'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062748mobile-tablets-and-wearables.svg', 
        text: 'Mobile, Tablets and Wearables'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062741home-kitchen-appliances.svg', 
        text: 'Home & Kitchen Appliances'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062756sweets-savouries.svg', 
        text: 'Sweets & Savouries'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727976546892dry-fruits-dates-seeds.svg', 
        text: 'Dry Fruits, Dates & Seeds'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062746meat-poultry.svg', 
        text: 'Meat & Poultry'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062737furniture-home-decor.svg', 
        text: 'Furniture & Home Decor'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062730footwear.svg', 
        text: 'Footwear'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062752sports-accessories.svg', 
        text: 'Sports Accessories'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062749paints-hardware.svg', 
        text: 'Paints & Hardware'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062750pet-food-accessories.svg', 
        text: 'Pet Food & Accessories'
      },
      {
        image: 'https://app.apnabillbook.com/uploads/1727977062739gifts-toys.svg', 
        text: 'Gifts & Toys'
      },
  ];