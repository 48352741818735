import React, { useEffect, useState } from 'react'
import PageTitle from '../Section/PageTitel/Pagetitle'
import background from "../../Assest/Banner/Container.png"
import Googleplay from '../Buttom/Googleplay';
import Window from '../Buttom/Window';
import { TiTick } from 'react-icons/ti';
import Customerudhaar from '../../Assest/Banner/image 320.png'
import ProductCategory from '../ProductCategory/ProductCategory';
import Pricing from '../Pricing/Pricing';
import ContactUsFooter from '../Footer/ContactUsFooter';
import AppleStoreButton from '../Buttom/Applestore';

const CustomerUdhaar = () => {
  const [fix, setFix] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };


  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", Navstick);
    return () => {
      window.removeEventListener("scroll", Navstick);
    };
  }, []);
  return (
    <>
      <div
        className={fix ? "w-full  bg-white" : " bg-white"}
      >
        <div className={`lg:px-16 border border-top-1 py-3 ${fix ? "fixed border border-top-1 shadow-sm bg-white w-full mt-16 z-50 " : " "
          }`}>
          <PageTitle
            activeMenu="Customer Udhaar"
          />
        </div>
      </div>
      <div className=" w-full " >
        <div className=" overflow-hidden relative  inset-0 ">
          <img src={background} alt="" className="absolute  inset-0 w-full h-screen object-cover " />
          <div class="relative lg:px-20 md:px-14 px-6 py-6  lg:flex md:flex justify-between items-center space-x-4 lg:mt-10 mt-2 ">
            <div >
              <h3 className="text-white lg:text-5xl text-[23px] uppercase tracking-wider  xl:mb-2">
                <h1 class="lg:space-y-8"> Customer Udhaar, made Easy
                </h1>
              </h3>
              <div class="lg:mt-10  mt-3  text-white tracking-wider text-base  lg:text-2xl ">

                <h2>
                  <span>
                    Easily track your inventory, know which items sell fast, which items expire soon. Invest in the right inventory to maximize your profits..</span>
                </h2>
                <div className="lg:my-10 md:my-5 my-3 lg:flex  lg:space-x-4 md:flex md:space-x-4 grid grid-cols-2 gap-3 mb-8 " >
                  <Googleplay />
                  <AppleStoreButton/>
                  <Window />
                </div>
              </div>
            </div>
            <div className="">
              <div className=" flex justify-center  w-full z-10">
                <div className="flex justify-center">

                  <div className="mt-2 
                    items-center  mx-10 -translate-x-2 -rotate-0 rounded-3xl bg-white p-1">
                    <div className=" translate-x-0 rotate-0 overflow-hidden rounded-3xl shadow-lg transition-transform hover:scale-100 ">
                      <video autoPlay muted loop className="h-full w-full  object-cover rounded-3xl"
                        poster="https://app.apnabillbook.com/getstarted.jpg"
                      >
                        <source src="https://app.apnabillbook.com/getstarted.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="bg-sky-50 py-16 mx-auto container lg:px-20 md:px-16 px-10">
        <div className="flex justify-between items-center space-x-10">
          <div className="">
            <img
              src={Customerudhaar}
              alt="Inventory"
              className="rounded-lg shadow-md"
            />
          </div>
          <div className="">
            <h1 className="text-3xl font-bold text-[#0E7CF2] mb-6">
              Cloud base Online Store
            </h1>
            <div className="">
              <div className="">
                <p className="text-gray-700">
                  Inventory count is a time-consuming task, but we've made it easier
                  for you. Whether your customers buy in-store, on your website, or
                  at an online marketplace, your stock levels are instantly updated
                  everywhere. Keep track of your inventory with Apna Billbook and
                  reorder low-stock items in advance.
                </p>
                <h2 className="text-xl font-semibold  mt-8 mb-4">
                  Here are three compelling reasons why you should use Apna Billbook for
                  your inventory management needs.
                </h2>
                <ul className="list-disc pl-8">
                  <li className="flex  items-center mb-2">
                    < TiTick className="mr-3 bg-[#0E7CF2] rounded-full text-white " />
                    View a list of all your products with real-time quantity levels.
                  </li>
                  <li className="flex  items-center mb-2">
                    < TiTick className="mr-3 bg-[#0E7CF2] rounded-full text-white " />
                    Syncs stock across online and offline channels.
                  </li>
                  <li className="flex  items-center mb-2">
                    < TiTick className="mr-3 bg-[#0E7CF2] rounded-full text-white " />
                    Your data is forever secure even when you switch to a different
                    device.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <ProductCategory />
      </div>
      <div className="">
        <Pricing />
      </div>

      <ContactUsFooter />
    </>
  )
}

export default CustomerUdhaar;